<template>
  <div class="wrapper">
    <img class="img" :src="image" />
  </div>
</template>

<script>
export default {
  name: 'ShowImage',
  data() {
    return {
      image: decodeURIComponent(this.$route.query.img),
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  width: 100%;
}
</style>
